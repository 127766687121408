import React, {Suspense} from 'react';

import {BrowserRouter, HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import './scss/style.scss'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import AuthProvider, {AuthContext, useAuth} from "./store/context/auth";
import Loader from "./components/Loader";
import {ContainerProvider} from "../src/utils/container";

const loading = () =>  <Loader/>;
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/Login/Login'));
const Home = React.lazy(() => import('./views/Home/NewHome'));
const Privacy = React.lazy(() => import('./views/Privacy/Privacy'));
const AccountRemovalRequest = React.lazy(() => import('./views/AccountRemoval/AccountRemoval'));



function App (){

    return (
      <AuthProvider>
        <ContainerProvider>
        <BrowserRouter >
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/" name="Home Page" render={props => <Home {...props}/>} />
              <Route exact path="/privacy" name="Privacy Page" render={props => <Privacy {...props}/>} />
              <Route exact path="/account-remove" name="Account Removal request" render={props => <AccountRemovalRequest {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route path="*"  render={(props) =>   localStorage.getItem('access_token') ?<DefaultLayout {...props}/> : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}/>

            </Switch>
          </React.Suspense>
      </BrowserRouter>
        </ContainerProvider>
      </AuthProvider>
    );

}

export default App;
