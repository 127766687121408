import {createContext, useContext, useEffect} from 'react';
import * as AuthReducer from "../reducers/auth_reducer";
import React,{useReducer, useMemo} from 'react';

export const AuthContext = createContext(AuthReducer.initialState);

export function useAuth() {

  const {state,dispatch} = useContext(AuthContext)
  return {state,dispatch}
}

const countInitializer = initialState => {
  return initialState
};

const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(AuthReducer.AuthReducer, AuthReducer.initialState,countInitializer)

  const value = useMemo(() => ({ state, dispatch }), [state,dispatch])


  return (
    <AuthContext.Provider value={{...value}}>
      {children}
    </AuthContext.Provider>

  )
}
export default (AuthProvider)
