import React from 'react'
import {Audio, Hearts} from 'react-loader-spinner'

const Loader = () => {
  return (
    <div style={{

      content: '',
      display: 'block',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, .8)',
      'z-index': '998'





    }}>

      <div style={{ 'position': 'fixed',
        'z-index': '999',
        height: '10em',
        width: '10em',
        overflow: 'show',
        margin: 'auto',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,}}>
      <Hearts
        height="100"
        width="100"
        color='grey'
        ariaLabel='loading'
      />
      </div>
    </div>

  )
}

export default React.memo(Loader)
